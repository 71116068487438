<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        For the indicated values of
        <stemble-latex content="$\ell$" />
        below, what are the possibilities for the magnetic quantum number,
        <stemble-latex content="$m_\ell$" />
        ?
      </p>

      <p class="mb-2">
        <stemble-latex content="$\ell = 2$" />
      </p>

      <p v-for="option in options1" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="pl-8 mb-0 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mt-5 mb-2">
        <stemble-latex content="$\ell = 4$" />
      </p>

      <div v-for="option in options2" :key="'pt-2-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input2" :value="option.value" class="pl-8 mb-0 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question20',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: '$\\text{-2, -1, 1, 2}$', value: 'a'},
        {text: '$\\text{-2, 2}$', value: 'b'},
        {text: '$\\text{-1, 0, 1}$', value: 'c'},
        {text: '$\\text{-2, -1, 0, 1, 2}$', value: 'd'},
      ],
      options2: [
        {text: '$\\text{-3, -2, -1, 0, 1, 2, 3}$', value: 'a'},
        {text: '$\\text{-4, -3, -2, -1, 0, 1, 2, 3, 4}$', value: 'b'},
        {text: '$\\text{0, 1, 2, 3, 4}$', value: 'c'},
        {text: '$\\text{-4, -3, -2, -1, 1, 2, 3, 4}$', value: 'd'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
